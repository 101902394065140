import styled from "styled-components";

export const Container = styled.div``;

export const StepNav = styled.nav``;

export const ButtonNav = styled.nav`
  padding-top: 24px;
`;

export const SettingsHolder = styled.div``;

export const PreviewHolder = styled.div``;

export const Description = styled.div`
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 24px;
`;
