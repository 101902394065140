import * as React from "react";

import { Helmet } from "react-helmet";
import { Hero, RecipeList, useData, useFavorites, useAssetUrl } from "..";

export function FavoritesRoute() {
  const data = useData();
  const [favs] = useFavorites();

  const recipes = React.useMemo(
    () => favs.map((fav) => data.recipes[fav]).filter(Boolean),
    [data, favs]
  );

  const imageUrl = useAssetUrl(
    "recipes_thumb",
    recipes?.[0]?.id || "5469feafff604d2c8b8b4569"
  );

  return (
    <>
      <Helmet>
        <title>Favoriten | Rezepte | zilles.io</title>
      </Helmet>
      <Hero title="Favoriten" imageUrl={imageUrl} />
      <RecipeList recipes={recipes} />
    </>
  );
}
