import * as React from "react";
import { DataItemInterface, useOpenDashServices, useForceRender } from "..";

export function useDataItems(searchString?: string): DataItemInterface[] {
  const { DataService } = useOpenDashServices();
  const forceRender = useForceRender();

  React.useEffect(() => {
    return DataService.subscribeItemList(() => {
      forceRender();
    });
  }, []);

  return React.useMemo(() => {
    if (searchString) {
      const searchStrings = searchString.toLowerCase().split(" ");

      return DataService._listOrThrowSync().filter(item => {
        const searchIndex = [item.id, item.name].join("~").toLowerCase();

        return searchStrings.every(searchTerm =>
          searchIndex.includes(searchTerm)
        );
      });
    }

    return DataService._listOrThrowSync();
  }, [forceRender, searchString]);
}
