import * as React from "react";

import { Helmet } from "react-helmet";
import { useUrlParam } from "opendash";
import { RecipeList, SearchBar, useSearch, useAssetUrl } from "..";
import { Hero } from "../components/Hero";

export function RecipesRoute() {
  const recipes = useSearch();

  const imageUrl = useAssetUrl(
    "recipes_thumb",
    recipes?.[0]?.id || "5469feafff604d2c8b8b4569"
  );

  return (
    <div>
      <Helmet>
        <title>Rezepte | zilles.io</title>
      </Helmet>
      <Hero title="Rezepte" imageUrl={imageUrl} />
      <SearchBar />
      <RecipeList recipes={recipes} />
    </div>
  );
}
