import * as React from "react";

import * as $data from "../services/data.service";

import { RecipesContext, RecipeContextType, Loading } from "..";

export function RecipesProvider({ host, children }) {
  const [data, setData] = React.useState<RecipeContextType>();

  React.useEffect(() => {
    $data.init(host).then(
      (ok) => {
        setData(ok);
      },
      (err) => {
        console.error(err);
      }
    );
  }, [host]);

  if (!data) {
    return <Loading message="Lade neue Rezept Datenbank herunter.." />;
  }

  return <RecipesContext.Provider value={data} children={children} />;
}
