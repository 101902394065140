const DISALLOED_HUE_RANGES = [
  // [20, 210],
  // [240, 340]
];

const NUMBER_RANGE =
  360 -
  DISALLOED_HUE_RANGES.reduce(
    (previous, [start, end]) => previous + (end - start),
    0
  );

export function stringToColor(str: string): string {
  const hash = stringToHash(str);
  const color = numberToColor(hash);

  return color;
}

function stringToHash(str: string): number {
  let hash = 0;

  str = btoa(str);

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  hash = hash * 9301 + 49297;

  return hash;
}

function numberToColor(hash: number): string {
  hash = Math.abs(hash);

  const saturation = 70;
  const lightness = 40;

  const hue = DISALLOED_HUE_RANGES.reduce(
    (acc, [start, end]) => (acc >= start ? acc + (end - start) : acc),
    hash % NUMBER_RANGE
  );

  const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;

  return color;
}

// test:
// new Array(1000).fill(0).forEach((_, i) => console.info(numberToColor(i)));
