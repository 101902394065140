import * as React from "react";
import styled from "styled-components";

const Container = styled.div`
  height: 100%;
  min-height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContainerInner = styled.div`
  text-align: center;
`;

const Animation = styled.div`
  display: block;
  position: relative;
  width: 64px;
  height: 64px;
  margin: 40px auto;

  div {
    animation: animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px;
  }

  div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: black;
    margin: -3px 0 0 -3px;
  }

  div:nth-child(1) {
    animation-delay: -0.036s;
  }

  div:nth-child(1):after {
    top: 50px;
    left: 50px;
  }

  div:nth-child(2) {
    animation-delay: -0.072s;
  }

  div:nth-child(2):after {
    top: 54px;
    left: 45px;
  }

  div:nth-child(3) {
    animation-delay: -0.108s;
  }

  div:nth-child(3):after {
    top: 57px;
    left: 39px;
  }

  div:nth-child(4) {
    animation-delay: -0.144s;
  }

  div:nth-child(4):after {
    top: 58px;
    left: 32px;
  }

  div:nth-child(5) {
    animation-delay: -0.18s;
  }

  div:nth-child(5):after {
    top: 57px;
    left: 25px;
  }

  div:nth-child(6) {
    animation-delay: -0.216s;
  }

  div:nth-child(6):after {
    top: 54px;
    left: 19px;
  }

  div:nth-child(7) {
    animation-delay: -0.252s;
  }

  div:nth-child(7):after {
    top: 50px;
    left: 14px;
  }

  div:nth-child(8) {
    animation-delay: -0.288s;
  }

  div:nth-child(8):after {
    top: 45px;
    left: 10px;
  }

  @keyframes animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Message = styled.span`
  padding-top: 100px;
  font-style: italic;
`;

export function Loading({ message }) {
  return (
    <Container>
      <ContainerInner>
        <Animation>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </Animation>
        <Message>{message}</Message>
      </ContainerInner>
    </Container>
  );
}
