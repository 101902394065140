import * as React from "react";
import styled from "styled-components";

import { Button, Select } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

import {
  RecipeType,
  LikeButton,
  Ingredient,
  Instruction,
  Markdown,
  CartButton,
  useAssetUrl,
  useRecipeSourceUrl,
  useRecipeIngredients,
} from "..";

const Container = styled.div`
  margin: 0 auto;
  max-width: 800px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  background: white;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;

  :nth-child(2) {
    margin-top: -20px;
    z-index: 2;
  }
`;

const Image = styled.div`
  height: 200px;

  @media (min-width: 600px) {
    height: 300px;
  }

  @media (min-width: 800px) {
    height: 400px;
  }

  margin: 0 -20px;

  overflow: hidden;
  position: relative;

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`;

const Actions = styled.div`
  float: right;
`;

const Title = styled.div`
  font-size: 24px;
  letter-spacing: -0.3px;
  /* line-height: 1.5; */
  font-weight: 400;
`;

const SubTitle = styled.div`
  font-size: 16px;
  letter-spacing: -0.3px;
  /* line-height: 1.5; */
  font-weight: 400;
`;

const Description = styled.div``;

const Note = styled.div`
  text-align: center;
  margin-top: 20px;
  color: rgba(0, 0, 0, 0.3);
`;

const Source = styled.div`
  text-align: center;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.2);
  text-decoration: none;

  a {
    color: rgba(0, 0, 0, 0.2);
    text-decoration: none;
  }
`;

export function RecipeView({ recipe }: { recipe: RecipeType }) {
  const imageUrl = useAssetUrl("recipes_large", recipe.id);
  const pdfLink = useAssetUrl("pdf", recipe.id);
  const srcLink = useRecipeSourceUrl(recipe.id);
  const [yields, setYields] = React.useState(2);
  const ingredients = useRecipeIngredients(recipe, yields);

  const date = formatDate(recipe);

  return (
    <div>
      <Image style={{ backgroundImage: `url(${imageUrl})` }} />
      <Container>
        <Actions>
          <LikeButton id={recipe.id} />
        </Actions>
        <Title>{recipe.name}</Title>
        <SubTitle>{recipe.headline}</SubTitle>
        <Description>
          <Markdown source={recipe.descriptionMarkdown} />
        </Description>
      </Container>
      <Source>
        <span>{date}</span>
        {" | "}
        <a href={srcLink} target="_blank" rel="noopener noreferrer">
          JSON Download
        </a>
        {" | "}
        <a href={pdfLink} target="_blank" rel="noopener noreferrer">
          PDF Download
        </a>
      </Source>
      <Container>
        <Actions>
          <Select
            style={{ marginRight: 5 }}
            value={yields}
            onChange={(value) => {
              setYields(value);
            }}
          >
            <Select.Option value={1}>Eine Person</Select.Option>
            <Select.Option value={2}>2 Personen</Select.Option>
            <Select.Option value={3}>3 Personen</Select.Option>
            <Select.Option value={4}>4 Personen</Select.Option>
            <Select.Option value={5}>5 Personen</Select.Option>
            <Select.Option value={6}>6 Personen</Select.Option>
          </Select>

          <CartButton id={recipe.id} yields={yields} text={true} />
        </Actions>
        <h3>Zutaten</h3>
        {ingredients.map((ingredient) => (
          <Ingredient key={ingredient.id} {...ingredient} />
        ))}
        <Note>* sollte man Zuhause haben</Note>
      </Container>
      <Container>
        <Actions>
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            href={pdfLink}
            target="_blank"
          >
            PDF herunterladen
          </Button>
        </Actions>
        <h3>Zubereitung</h3>

        {recipe.steps.map((step) => (
          <Instruction key={step} id={step} />
        ))}
      </Container>
    </div>
  );
}

function formatDate(recipe) {
  try {
    const date = new Date(Date.parse(recipe.updatedAt));

    return date.toLocaleDateString("de-DE", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  } catch (error) {
    return null;
  }
}
