import * as React from "react";
import { Button } from "antd";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { useCart } from "..";

export function CartButton({ id, yields, text = false }) {
  const [cart, setCart] = useCart();

  return (
    <Button
      type="primary"
      icon={<ShoppingCartOutlined />}
      title="Zur Einkaufsliste hinzufügen"
      onClick={() => {
        setCart([...cart.filter(([cid]) => cid !== id), [id, yields]]);
      }}
    >
      {text && "Zur Einkaufsliste"}
    </Button>
  );
}
