import * as React from "react";
import styled from "styled-components";

import { useRecipesData, useAssetUrl } from "..";

const Container = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  line-height: 60px;
  height: 60px;

  overflow: hidden;

  @media (min-width: 600px) {
    line-height: 100px;
    height: 100px;
  }

  > *:not(:first-child) {
    margin-left: 5px;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const Image = styled.div`
  width: 60px;
  height: 60px;

  @media (min-width: 600px) {
    width: 100px;
    height: 100px;
  }

  padding: 5px;
  float: left;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export function Ingredient({ id, amount, unit }) {
  const ingredient = useRecipesData("ingredients", id);
  const image = useAssetUrl("ingredients", id);

  if (!ingredient) {
    return (
      <Container>
        <span>Zutat nicht gefunden.</span>
      </Container>
    );
  }

  return (
    <Container>
      {image && (
        <Image>
          <img src={image} alt="" />
        </Image>
      )}
      <div style={{ float: "left" }}>{ingredient.name}</div>
      <div style={{ float: "right" }}>
        {amount && unit ? amount + " " + unit : ""}
      </div>
    </Container>
  );
}
