export default {
  auth: {
    login: "Login",
    login_title: "Login",
    login_failed: "Login failed",
    logout: "Logout",
    signup: "Create an Account",
    signup_title: "Sign up",
    signup_failed: "Account creation failed.",
    greeting_dropdown: "Hello,",
    switch_to_signup: "No account yet? Create one now.",
    switch_to_login: "You already have an account? Log in instead.",
    fields: {
      username: "Username",
      username_required: "Username is required.",
      name: "Name",
      name_required: "Name is required.",
      email: "E-Mail Adress",
      email_required: "E-Mail Adress is required.",
      password: "Password",
      password_required: "Please enter a password.",
      login_submit: "Login",
      signup_submit: "Create",
    },
  },
  account: {
    language: {
      label: "Language Settings",
      info: "Please select your prefered language. It will be saved on the device and will be used the next time you load the application",
      placeholder: "Select your language",
    },
    settings: { label: "Account Settings" },
  },
  users: {
    label: "User",
    label_plural: "Users",
    attributes: {
      name: "Name",
      email: "E-Mail",
      username: "Username",
      password: "Password",
    },
  },
  frontpage: {
    empty: "Nothing to show here",
  },
  locations: {
    label: "Location",
    label_plural: "Locations",
    attributes: {
      name: "Name",
      spsid: "Steuerung",
      type: "Typ",
      price: "Preis",
      city: "Stadt",
      postCode: "Plz",
      address: "Adresse",
      province: "Bundesland",
      latitude: "Lat.",
      longitude: "Long.",
      location: "Koordinaten",
    },
  },
  error: {
    reload: "Reload",
    reset: "Reset Application",
  },
  ui: {
    ok: "OK",
    cancel: "Cancel",
    save: "Save",
    next: "Next",
    back: "Back",
    create: "Create",
    update: "Update",
    edit: "Edit",
    delete: "Delete",
    share: "Share",
    rename: "Rename",
    no_data: "No Data",
    copyright: "",
    today: "Today",
    now: "Now",
    reset: "Reset",
    day: "Day",
    day_plural: "Days",
    week: "Week",
    week_plural: "Weeks",
    month: "Month",
    month_plural: "Months",
    year: "Year",
    year_plural: "Years",
    filter: "Filter",
    start_date: "Start date",
    end_date: "End date",
    select_time: "Select Time",
    select_month: "Select Month",
    select_year: "Select Year",
    select_decade: "Select Decade",
    select_all: "Select all",
    select_invert: "Invert selection",
    select_linked_data: "Linked Data",
    select_date: "Select a date",
    select_date_range: "Select a date range",
    select_date_linked: "Select the linked date range",
    select_data_item: "Select a data item",
    select_data_item_type: "Select a data item value",
    select_data_item_linked: "Select the linked data item",
    search_placeholder: "Type here to search..",
  },
  languages: {
    en: { label: "English" },
    de: { label: "German" },
  },
  dashboards: {
    title: "Dashboards",
    management_title: "Dashboard Management",

    editmode: {
      action: "Edit Mode",
    },

    change: {
      action: "Change dashboard",
      action_desc: "Change dashboard",
    },
    create: {
      action: "Create dashboard",
      action_desc: "Create dashboard",
    },
    rename: {
      action: "Rename dashboard",
      action_desc: "Rename dashboard",
    },
    delete: {
      action: "Delete dashboard",
      action_desc: "Delete dashboard",
    },
    create_modal_title: "Dashboard Creation",
    create_modal_description: "Please give the dashboard a descriptive name.",
    create_modal_tooltip: "Click here, to create a new dashboard",
    create_modal_placeholder: "Enter the name here...",
    rename_modal_title: "Dashboard Renaming",
    rename_modal_description: "Give the dashboard a new name.",
    rename_modal_tooltip: "Click here, to give the dashboard a new name",
    rename_modal_placeholder: "Enter the new name here...",
    delete_modal_title: "Dashboard Deletion",
    delete_modal_description: "Do you want to delete this dashboard? This action can't be undone.",
    delete_modal_tooltip: "Open the dashboard deletion dialog",
    share_modal_title: "Dashboard Sharing",
    share_modal_description: "This will share the dashboard with the selected users.",
    share_modal_tooltip: "Open the dashboard sharing dialog",
  },
  widgets: {
    create: { action: "Add Widgets" },
    rename_modal_title: "Widget Renaming",
    rename_modal_description: "Rename the widget label.",
    rename_modal_tooltip: "Open the widget renaming dialog",
    rename_modal_placeholder: "Enter the new name here...",
    delete_modal_title: "Widget Deletion",
    delete_modal_description: "Do you want to delete this widget? This action can't be undone.",
    delete_modal_tooltip: "Open the widget deletion dialog",
    share_modal_title: "Widget Sharing",
    share_modal_description: "This will share the widget with the selected users.",
    share_modal_tooltip: "Open the widget sharing dialog",
    reload: "Reload",
    reload_tooltip: "Reload/refresh/reset the widget",
    settings: "Settings",
    settings_modal_title: "Widget Settings",
    settings_modal_tooltip: "Open the widget settings dialog",
    fullscreen_action: "Fullscreen",
    fullscreen_tooltip: "Show the widget in fullscreen mode",
    catalogue: {
      title: "Add Widget from Presets",
      description: "Please select one or more widget presets.",
      ok_button: "Hinzufügen",
    },
  },

  sources: {
    label: "Source",
    label_plural: "Sources",
    tag: "Tag",
  },

  data: {
    item: {
      label: "Data Item",
      label_plural: "Data Items",
    },
    item_dimension: {
      label: "Data Item Dimension",
      label_plural: "Data Item Dimension",

      types: {
        Number: "Number",
        Boolean: "Boolean",
        String: "String",
        Geo: "Geo",
        Object: "Object",
      },
    },
    viewer: {
      col_name: "Name",
      col_type: "Type",
      col_value: "Value",
      col_time: "Time",
    },
  },

  monitoring: {
    label: "Monitoring",

    history_options: {
      live_enabled: "Load live data",
      history_enabled: "Load history data",

      type_relative: "Relative selection",
      type_absolute: "Absolute selection",

      aggregation_none: "No aggregation",
      aggregation_sum: "Aggregate: Sum",
      aggregation_mean: "Aggregate: Mean",
      aggregation_max: "Aggregate: Max",
      aggregation_min: "Aggregate: Min",
      aggregation_count: "Aggregate: Count",
    },

    data_sidebar: {
      title: "Data Sidebar",

      action: "Data Sidebar",
      action_desc: "Open the Data Sidebar",

      col_name: "Name",
      col_value: "Value",
      col_date: "Changed",
      search_placeholder: "Type here and press enter to search...",
    },

    data: {},

    widgets: {
      settings: {
        label: "Data Explorer",
        step_0_title: "Data",
        step_0_subtitle: "One selected",
        step_0_subtitle_plural: "{{count}} selected",
        step_0_description: "Select the data items, you want to explore.",
        step_1_title: "Time Range",
        step_1_subtitle: "",
        step_1_description: "Select the time, for which the data should be fetched.",
        step_2_title: "Settings",
        step_2_subtitle: "",
        step_2_description: "Fine tune your widget.",
        step_2_no_settings: "No settings available.",
      },
      config_error: {
        items_min: "Bad config: Not enough items selected",
        items_max: "Bad config: Too many items selected",
        items_type: "Bad config: Items of the wrong data type selected",
        items_notfound: "Missing data items config...",
        items_unsupported: "Widget Error (Item Config Unsupported)",
        history_notfound: "Missing data history config...",
        history_unsupported: "Widget Error (History Config Unsupported)",
      },
    },
  },

  data_explorer: {
    label: "Data Explorer",
    step_0_title: "Data",
    step_0_subtitle: "One item selected",
    step_0_subtitle_plural: "{{count}} items selected",
    step_0_description: "Select the data items, you want to explore.",
    step_1_title: "Time Range",
    step_1_subtitle: "",
    step_1_description: "Select the time, for which the data should be fetched.",
    step_2_title: "Visualisation",
    step_2_subtitle: "",
    step_2_description: "Select the way, your data should be displayed.",
    step_3_title: "Settings",
    step_3_subtitle: "",
    step_3_description: "Fine tune your visualisation.",
    step_3_no_settings: "No settings available.",
    visualisation_time_missing: "You first need to select a time range.",
    visualisation_data_missing: "You first need to select some data items.",
    visualisation_data_loading: "Fetching data..",
    visualisation_data_error: "There was an error while fetching the data.",
    visualisation_coming_soon: "coming soon",
    timeseries: {
      style: "Choose the type of visualization",
      style_line: "Linechart",
      style_area: "Areachart",
      style_scatter: "Scatterchart",
      style_bar: "Barchart",
      interaction_options: "Interaction options",
      interaction_options_description: "Interaction options description...",
      interaction_legend: "Show legend",
      interaction_tooltips: "Show tooltips",
      interaction_zoom_x: "Allow to zoom on the x-axis",
      interaction_zoom_y: "Allow to zoom on the y-axis",
      interaction_minimap: "Show a mini map for zooming",
      axis_options: "Axis options",
      axis_options_description: "Axis options description...",
      xaxis_label: "X-axis label",
      yaxis_auto: "Automatically create one y-axis per unit",
      yaxis_label: "Y-axis label",
    },
    pie: {
      general_options: "General options",
      style: "Choose the type of visualization",
      style_pie: "Pie",
      style_donut: "Donut",
      style_half_donut: "Half donut",
      aggregation: "Choose the aggregation",
      aggregation_sum: "Sum",
      aggregation_mean: "Mean",
      aggregation_max: "Max",
      aggregation_min: "Min",
      aggregation_count: "Count",
      interaction_options: "Interaction options",
      interaction_legend: "Show legend",
      interaction_options_description: "Interaction options description...",
    },
  },
  format: {
    datetime: "dd.MM.yyyy HH:mm:ss",
    date: "dd.MM.yyyy",
    time: "HH:mm:ss",
    time_ms: "HH:mm:ss,SSS",
    year: "yyyy",
    day: "d",
  },
};
