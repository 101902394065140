import React from "react";
import Highcharts from "highcharts";

interface Props {
  options: Highcharts.Options;
  style?: React.CSSProperties;
}

export const HighchartsGantt = React.memo<Props>(({ options, style }) => {
  const element = React.useRef<HTMLDivElement>(null);
  const chart = React.useRef<Highcharts.Chart>(null);

  React.useEffect(() => {
    // @ts-ignore
    chart.current = Highcharts.ganttChart(element.current, options);
    return () => {
      chart.current.destroy();
    };
  }, []);

  React.useEffect(() => {
    if (chart.current) {
      chart.current.update(options, true, true, true);
    }
  }, [options]);

  return <div ref={element} style={style}></div>;
});
