import * as React from "react";

import { Helmet } from "react-helmet";
import { compare } from "opendash";
import {
  Hero,
  Container,
  useCartRecipes,
  useCartIngredients,
  useAssetUrl,
  Ingredient,
} from "..";

export function CartRoute() {
  const recipesWithYields = useCartRecipes();
  const ingredients = useCartIngredients();

  const imageUrl = useAssetUrl(
    "recipes_thumb",
    recipesWithYields?.[0]?.[0]?.id || "5469feafff604d2c8b8b4569"
  );

  return (
    <>
      <Helmet>
        <title>Einkaufsliste | Rezepte | zilles.io</title>
      </Helmet>

      <Hero title="Einkaufsliste" imageUrl={imageUrl} />

      <Container>
        {recipesWithYields.map(([recipe, yields]) => (
          <div>
            {recipe.name} - {yields}
          </div>
        ))}
      </Container>

      <Container>
        {ingredients.map((ingredient) => (
          <Ingredient key={ingredient.id + ingredient.unit} {...ingredient} />
        ))}
      </Container>
    </>
  );
}
