import * as React from "react";
import styled from "styled-components";

import { Markdown, useRecipesData, useAssetUrl } from "..";

const Container = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding: 20px 0;

  @media (min-width: 600px) {
    display: flex;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const Image = styled.div`
  margin: 0 auto;
  margin-bottom: 20px;
  width: 300px;
  min-width: 300px;

  @media (min-width: 600px) {
    margin: 0;
    margin-right: 20px;
  }

  img {
    width: 300px;
    height: auto;
  }
`;

export function Instruction({ id }) {
  const instruction = useRecipesData("instructions", id);

  if (!instruction) {
    return "Instruktion nicht gefunden.";
  }

  return <InstructionInner instruction={instruction} />;
}

function InstructionInner({ instruction }) {
  const image = useAssetUrl("instructions_thumb", instruction.id);

  return (
    <Container>
      {image && (
        <Image>
          <img src={image} alt="" />
        </Image>
      )}
      <div>
        <Markdown source={instruction.instructionsMarkdown} />
      </div>
    </Container>
  );
}
