import * as React from "react";

import { useCart, useData, RecipeType } from "..";

export function useCartRecipes(): [RecipeType, number][] {
  const data = useData();
  const [cart] = useCart();

  return React.useMemo(
    () =>
      cart
        .map(
          ([id, yields]) => [data.recipes[id], yields] as [RecipeType, number]
        )
        .filter(([recipe]) => recipe),
    [data, cart]
  );
}
