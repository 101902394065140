import * as React from "react";
import styled from "styled-components";

import { FilterOutlined } from "@ant-design/icons";

import { Input, Button, Drawer, List, Checkbox, Divider } from "antd";

import { useUrlParam } from "opendash";
import { useData } from "..";

const Container = styled.div`
  display: flex;

  margin-bottom: 20px;
`;

const SearchContainer = styled.div`
  flex: 1;

  .ant-input {
    width: 100%;
  }
`;

const ActionContainer = styled.div`
  padding-left: 20px;
`;

export function SearchBar() {
  // const
  const [showFilter, setShowFilter] = React.useState(false);
  const [searchString, setSearchString] = useUrlParam("q", "", "string");

  return (
    <>
      <Container>
        <SearchContainer>
          <Input.Search
            defaultValue={searchString}
            placeholder="Nach Rezept suchen..."
            size="large"
            // value={searchString}
            onSearch={(value) => setSearchString(value)}
            onChange={(e) => {
              if (!e.target.value) {
                setSearchString(e.target.value);
              }
            }}
          />
        </SearchContainer>
        <ActionContainer>
          <Button
            icon={<FilterOutlined />}
            size="large"
            onClick={() => setShowFilter(true)}
          />
        </ActionContainer>
      </Container>

      <Drawer
        title="Filter"
        width={320}
        visible={showFilter}
        onClose={() => setShowFilter(false)}
      >
        <TagPicker />
      </Drawer>
    </>
  );
}

function TagPicker() {
  const data = useData();

  const tags = React.useMemo(() => {
    return Object.values(data.tags);
  }, [data]);

  const cuisines = React.useMemo(() => {
    return Object.values(data.cuisines);
  }, [data]);

  const [thermomix, setThermomix] = useUrlParam("thermomix", false);
  const [tagSelection, setTags] = useUrlParam("tags", []);
  const [cuisineSelection, setCuisines] = useUrlParam("cuisines", []);

  return (
    <>
      <List size="small">
        <List.Item
          actions={[
            <Checkbox
              checked={thermomix}
              onChange={(event) => {
                setThermomix(event.target.checked);
              }}
            />,
          ]}
        >
          Thermomix vorhanden?
        </List.Item>
      </List>
      <Divider />
      <List
        size="small"
        dataSource={cuisines}
        renderItem={(cuisine) => (
          <List.Item
            actions={[
              <Checkbox
                checked={cuisineSelection.includes(cuisine.id)}
                onChange={(event) => {
                  if (event.target.checked) {
                    setCuisines([...cuisineSelection, cuisine.id]);
                  } else {
                    setCuisines(
                      cuisineSelection.filter((id) => id !== cuisine.id)
                    );
                  }
                }}
              />,
            ]}
          >
            {cuisine.name}
          </List.Item>
        )}
      />
      <Divider />
      <List
        size="small"
        dataSource={tags}
        renderItem={(tag) => (
          <List.Item
            actions={[
              <Checkbox
                checked={tagSelection.includes(tag.id)}
                onChange={(event) => {
                  if (event.target.checked) {
                    setTags([...tagSelection, tag.id]);
                  } else {
                    setTags(tagSelection.filter((id) => id !== tag.id));
                  }
                }}
              />,
            ]}
          >
            {tag.name}
          </List.Item>
        )}
      />
    </>
  );
}
