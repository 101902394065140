import * as React from "react";
import { parse } from "markdown";

export function Markdown({ source }) {
  try {
    while (source.includes(":thermomix-reverse-on-off:")) {
      source = source.replace(":thermomix-reverse-on-off:", "Linkslauf");
    }

    return <div dangerouslySetInnerHTML={{ __html: parse(source) }} />;
  } catch (error) {
    console.error(error);
    return null;
  }
}
