import * as React from "react";

import { compare } from "opendash";

import { useCart, useData, IngredientAmountType } from "..";

export function useCartIngredients(): IngredientAmountType[] {
  const data = useData();
  const [cart] = useCart();

  return React.useMemo(() => {
    const result: Record<string, IngredientAmountType> = {};

    for (const [id, selectedYields] of cart) {
      const recipe = data.recipes[id];

      const yields = recipe.yields[0].yields;

      for (const ingredient of recipe.yields[0].ingredients) {
        const key =
          ingredient.amount && ingredient.unit
            ? ingredient.id + "-" + ingredient.unit
            : ingredient.id;

        if (!result[key]) {
          result[key] = {
            ...data.ingredients[ingredient.id],
            unit: ingredient.unit,
            amount: 0,
          };
        }

        result[key].amount +=
          Math.round((ingredient.amount / yields) * selectedYields * 10) / 10;
      }
    }

    return Object.values(result).sort(compare((x) => x.name));
  }, [data, cart]);
}
