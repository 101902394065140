import * as React from "react";
import { Button } from "antd";
import { HeartFilled } from "@ant-design/icons";
import { useFavorites } from "..";

export function LikeButton({ id, text = false }) {
  const [favorites, setFavorites] = useFavorites();

  const isFav = favorites.includes(id);

  return (
    <Button
      shape="circle"
      type={isFav ? "primary" : "default"}
      icon={<HeartFilled />}
      title={isFav ? "Von Favoriten entfernen" : "Zu Favoriten hinzufügen"}
      onClick={() => {
        if (isFav) {
          setFavorites(favorites.filter((cid) => cid !== id));
        } else {
          setFavorites([...favorites, id]);
        }
      }}
    >
      {text && isFav && "Von Favoriten entfernen"}
      {text && !isFav && "Zu Favoriten hinzufügen"}
    </Button>
  );
}
