import * as idb from "idb-keyval";

const cacheStore = new idb.Store("zilles-recipes", "cache");

export async function get(key) {
  return await idb.get(key, cacheStore);
}

export async function set(key, value) {
  return await idb.set(key, value, cacheStore);
}

export async function del(key) {
  return await idb.del(key, cacheStore);
}

export async function keys() {
  return await idb.keys(cacheStore);
}

export async function clear() {
  return await idb.clear(cacheStore);
}
