export default {
  locale: "en",
  locale_moment: "en_US",
  pagination_items_per_page: "/ page",
  pagination_jump_to: "Goto",
  pagination_jump_to_confirm: "confirm",
  pagination_page: "",
  pagination_prev_page: "Previous Page",
  pagination_next_page: "Next Page",
  pagination_prev_5: "Previous 5 Pages",
  pagination_next_5: "Next 5 Pages",
  pagination_prev_3: "Previous 3 Pages",
  pagination_next_3: "Next 3 Pages",
  datepicker_previous_month: "Previous month (PageUp)",
  datepicker_next_month: "Next month (PageDown)",
  datepicker_previous_year: "Last year (Control + left)",
  datepicker_next_year: "Next year (Control + right)",
  datepicker_previous_decade: "Last decade",
  datepicker_next_decade: "Next decade",
  datepicker_previous_century: "Last century",
  datepicker_next_century: "Next century",
  transfer_search: "Search here",
  transfer_item_unit: "item",
  transfer_item_unit_plural: "items",
  upload_uploading: "Uploading...",
  upload_remove: "Remove file",
  upload_error: "Upload error",
  upload_preview: "Preview file",
};
