import lunr from "lunr";

import * as $cache from "../services/cache.service";

export async function init(host) {
  const data = await initDatabase(host);
  const index = await initSearchIndex(Object.values(data.recipes));

  return { host, data, index };
}

async function initDatabase(host) {
  const cache = await $cache.get("database");

  if (cache) {
    return cache;
  }

  const url = host + "/recipes/data.json";

  const database = await fetch(url, {}).then((response) => response.json());

  await $cache.set("database", database);

  return database;
}

async function initSearchIndex(recipes) {
  const cache = await $cache.get("index-fulltext");

  if (cache) {
    return lunr.Index.load(JSON.parse(cache));
  }

  const index = lunr(function () {
    this.ref("id");

    this.field("name");
    this.field("headline");

    // this.field("descriptionMarkdown");

    this.pipeline.remove(lunr.stemmer);
    // this.pipeline.remove(lunr.trimmer);
    this.pipeline.remove(lunr.stopWordFilter);

    recipes.forEach((e) => this.add(e));
  });

  await $cache.set("index-fulltext", JSON.stringify(index));

  return index;
}
