import * as React from "react";
import styled from "styled-components";
import { Routes, Route } from "react-router-dom";

import {
  RecipesProvider,
  RecipesRoute,
  RecipesShowRoute,
  CartRoute,
  FavoritesRoute,
  Header,
} from "..";

const Container = styled.div`
  padding: 20px;

  padding-top: 60px;
`;

export function App() {
  return (
    <>
      <Header />
      <RecipesProvider host="https://static.recipes.zill.es">
        <Container>
          <Routes>
            <Route path="/" element={<RecipesRoute />} />
            <Route path="/favorites" element={<FavoritesRoute />} />
            <Route path="/cart" element={<CartRoute />} />
            <Route path="/recipe/:id" element={<RecipesShowRoute />} />
          </Routes>
        </Container>
      </RecipesProvider>
    </>
  );
}
