import * as React from "react";

import { RecipesContext } from "..";

export function useAssetUrl(group, id) {
  const { host } = React.useContext(RecipesContext);

  const extension = assetGroupToExtension(group);

  return `${host}/assets/${group}/${id}.${extension}`;
}

function assetGroupToExtension(group) {
  switch (group) {
    case "recipes":
    case "recipes_thumb":
    case "recipes_large":
    case "instructions":
    case "instructions_thumb":
      return "jpg";
    case "pdf":
      return "pdf";
    case "ingredients":
      return "png";
    default:
      throw new Error("Unknown Asset Group");
  }
}
