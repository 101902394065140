import * as React from "react";
import styled from "styled-components";

export const Container = styled.div`
  margin: 0 auto;
  max-width: 800px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  background: white;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;

  :nth-child(2) {
    margin-top: -20px;
    z-index: 2;
  }
`;
