import * as React from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";

import { useAssetUrl } from "..";

const linkStyle = {
  display: "block",
  color: "inherit",
};

const Text = styled.div`
  padding: 10px;
`;

const Image = styled.div`
  width: 100%;
  height: 200px;
  img {
    width: 100%;
    height: auto;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
`;

export function RecipeListItem({ recipe }) {
  const imageUrl = useAssetUrl("recipes_thumb", recipe.id);

  return (
    <Link to={`/recipes/recipe/${recipe.id}`} style={linkStyle}>
      <Image>
        <img src={imageUrl} alt="" />
      </Image>
      <Text>{recipe.name + " " + recipe.headline}</Text>
    </Link>
  );
}
