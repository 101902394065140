import * as React from "react";

import { useStorage, DataItemHistoryOptionsInterface } from "..";

type useWidgetDataItemResult = [
  DataItemHistoryOptionsInterface,
  (input: DataItemHistoryOptionsInterface) => void
];

export function useWidgetDataItemHistoryOptions(): useWidgetDataItemResult {
  return useStorage<DataItemHistoryOptionsInterface>(
    "device",
    "opendash:data:linked_history_options",
    {
      start: undefined,
      end: undefined,
    }
  );
}
