import * as React from "react";

import { compare } from "opendash";

import { useData, RecipeType, IngredientAmountType } from "..";

export function useRecipeIngredients(
  recipe: RecipeType,
  selectedYields?: number
): IngredientAmountType[] {
  const data = useData();

  return React.useMemo(() => {
    const yields = recipe.yields[0].yields;

    return recipe.yields[0].ingredients
      .map((ingredient) => {
        const amount =
          Math.round(
            (ingredient.amount / yields) * (selectedYields ?? yields) * 10
          ) / 10;

        return {
          ...data.ingredients[ingredient.id],
          unit: ingredient.unit,
          amount,
        };
      })
      .sort(compare((x) => x.name));
  }, [data, recipe, selectedYields]);
}
