import * as React from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";

const Container = styled.div`
  padding: 0 20px;
  height: 60px;
  line-height: 60px;

  background: white;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;

  z-index: 100;
`;

const Menu = styled.div`
  position: relative;
  display: flex;
`;

const MenuLeft = styled.div`
  display: flex;
  flex: 1 1;

  font-size: 1.2em;
`;

const MenuRight = styled.div`
  float: right;
  margin-left: 10px;
`;

const MenuLink = styled(Link)`
  display: inline-block;
  line-height: 60px;
  padding: 0 10px;
`;

export function Header() {
  return (
    <Container>
      <Menu>
        <MenuLeft>HalloFrische</MenuLeft>
        <MenuRight>
          <MenuLink to="/recipes">Rezepte</MenuLink>
          <MenuLink to="/recipes/favorites">Favoriten</MenuLink>
          {/* <MenuLink to="/recipes/cart">Einkaufsliste</MenuLink> */}
        </MenuRight>
      </Menu>
    </Container>
  );
}
