import Parse from "parse";

Parse.initialize("zilles");
Parse.serverURL = "https://users.zilles.io/parse";

export const ParseAdapterConfig = {
  userDataClass: "UserStorage",
};

export const ParseAdminConfig = [];
