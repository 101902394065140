import * as React from "react";
import styled from "styled-components";

import { RecipeListItem } from "..";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
`;

const Item = styled.div`
  width: 300px;
  margin: 5px;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
`;

const countPerPage = 40;
let watchScrollLock = false;

function watchScrollPosition(page, setPage, recipes) {
  if (!watchScrollLock && countPerPage * page < recipes.length && window) {
    const body = document.body;
    const html = document.documentElement;

    const bodyHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    const screenHeight = Math.max(html.clientHeight, window.innerHeight, 0);

    const yPos = Math.max(window.scrollY, window.pageYOffset);

    const scrollPositionAtBottom = bodyHeight <= yPos + screenHeight + 200;

    if (!watchScrollLock && scrollPositionAtBottom) {
      watchScrollLock = true;
      setPage(page + 1);
    }

    setTimeout(() => {
      watchScrollLock = false;
    }, 1000);
  }
}

export function RecipeList({ recipes }) {
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    const callback = () => watchScrollPosition(page, setPage, recipes);

    window.addEventListener("scroll", callback, false);

    return () => {
      window.removeEventListener("scroll", callback, false);
    };
  }, [recipes, page]);

  const recipesFiltered = React.useMemo(
    () => recipes.slice(0, countPerPage * page),
    [recipes, page]
  );

  if (!recipes || recipes.length === 0) {
    return <p style={{ textAlign: "center" }}>Keine Rezepte gefunden.</p>;
  }

  return (
    <div>
      <p style={{ textAlign: "center" }}>
        Es werden {recipes.length} Rezepte angezeigt.
      </p>
      <Container>
        {recipesFiltered.map((recipe) => (
          <Item key={recipe.id}>
            <RecipeListItem recipe={recipe} />
          </Item>
        ))}
      </Container>
    </div>
  );
}
