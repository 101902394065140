import * as React from "react";

import { Helmet } from "react-helmet";

import { useParams } from "react-router-dom";

import { RecipeView, Error, useRecipesData } from "..";

export function RecipesShowRoute() {
  const { id } = useParams();
  const recipe = useRecipesData("recipes", id);

  if (!recipe) {
    return <Error message="Rezept nicht gefunden." />;
  }

  return (
    <>
      <Helmet>
        <title>{recipe?.name} | Rezepte | zilles.io</title>
      </Helmet>
      <RecipeView recipe={recipe} />
    </>
  );
}
