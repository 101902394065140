import * as React from "react";

import { Layout } from "antd";

import { useTranslation } from "../..";

import Header from "./header";
import { LanguageSelection } from "./LanguageSelection";

import theme from "./theme";

export default function AppLayout({ children }) {
  const [t] = useTranslation(["opendash"]);

  return (
    <Layout style={theme.layout}>
      <Layout.Header style={theme.header}>
        <Header />
        <LanguageSelection />
      </Layout.Header>
      <Layout.Content style={theme.content}>{children}</Layout.Content>
      <Layout.Footer style={theme.footer}>{t("ui.copyright")}</Layout.Footer>
    </Layout>
  );
}
