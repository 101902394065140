// @ts-check

import "antd/dist/antd.min.css";
// import "react-grid-layout/css/styles.css";
// import "react-resizable/css/styles.css";

import { init, StorageAdapterLS } from "opendash";

import { ParseUserAdapter, ParseStorageAdapter } from "@opendash/plugin-parse";

import { ParseAdapterConfig } from "./parse.config";

import { App } from "./recipes";

init("opendash", async (factory) => {
  // Translations:
  factory.registerLanguage("en", "English");

  // Adapter + Plugins

  factory.registerDeviceStorageAdapter(new StorageAdapterLS());
  factory.registerUserAdapter(new ParseUserAdapter(ParseAdapterConfig));
  factory.registerUserStorageAdapter(
    new ParseStorageAdapter(ParseAdapterConfig)
  );

  // UI

  factory.ui.disableHeader();

  // Routing:

  factory.registerRoute({
    path: "/recipes/*",
    component: async () => ({ default: App }),
  });

  // Frontpage:

  // @ts-ignore
  factory.registerStaticNavigationItem({
    id: "recipes",
    label: "Rezepte",
    place: "frontpage",
    color: "red",
    icon: "smile",
    link: "/recipes",
    order: 10,
  });
}).then((app) => {
  console.log("init open.DASH");
});
