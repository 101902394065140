import * as React from "react";

import { useDeepCompareEffect, useUrlParam } from "opendash";

import { RecipesContext, useFavorites } from "..";
import { RecipeType } from "../types/RecipeType";

export function useSearch() {
  const { data, index } = React.useContext(RecipesContext);

  const [searchString] = useUrlParam("q", "", "string");
  const [thermomix] = useUrlParam("thermomix", false);
  const [filterFavorites] = useUrlParam("favorites", false);
  const [tagSelection] = useUrlParam("tags", []);
  const [cuisineSelection] = useUrlParam("cuisines", []);

  const [favorites] = useFavorites();

  const [result, setResult] = React.useState<RecipeType[]>([]);

  useDeepCompareEffect(() => {
    let nextRecipes: RecipeType[];

    const searchResult = searchString
      ? index.search(
          searchString
            .trim()
            .split(" ")
            .map((s) => `*${s}*`)
            .join(" ")
        )
      : undefined;

    nextRecipes = searchResult
      ? searchResult.map(({ ref }) => data.recipes[ref])
      : Object.values(data.recipes);

    nextRecipes = nextRecipes.filter((recipe) =>
      thermomix ? recipe.author === "thermomix" : recipe.author !== "thermomix"
    );

    if (filterFavorites) {
      nextRecipes = nextRecipes.filter((recipe) =>
        favorites.includes(recipe.id)
      );
    }

    if (tagSelection.length > 0) {
      nextRecipes = nextRecipes.filter((recipe) =>
        tagSelection.every((tag) => recipe.tags.includes(tag))
      );
    }

    if (cuisineSelection.length > 0) {
      nextRecipes = nextRecipes.filter((recipe) =>
        cuisineSelection.every((cuisine) => recipe.cuisines.includes(cuisine))
      );
    }

    if (!searchResult) {
      nextRecipes.sort(
        (a, b) =>
          new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf()
      );
    }

    setResult(nextRecipes);
  }, [searchString, thermomix, tagSelection, cuisineSelection, data, index]);

  return result;
}
