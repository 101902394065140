import * as React from "react";
import styled from "styled-components";

const Container = styled.div`
  height: 120px;
  line-height: 120px;
  font-size: 32px;

  overflow: hidden;
  position: relative;

  margin: 0 -20px;
  margin-bottom: 20px;

  @media (min-width: 600px) {
    font-size: 40px;
    height: 200px;
    line-height: 200px;
  }

  @media (min-width: 800px) {
    font-size: 48px;
    height: 300px;
    line-height: 300px;
  }
`;

const Title = styled.div`
  position: relative;

  text-align: center;
  color: white;
  text-shadow: 0 2px 2px #3b4756;
  text-transform: uppercase;
  font-weight: 900;

  letter-spacing: -0.1rem;
`;

const Image = styled.div`
  position: absolute;

  height: 100%;
  width: 100%;

  /* filter: grayscale(100%), blur(5px); */
  filter: blur(5px);
  transform: scale(1.1);

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`;

export function Hero({ title, imageUrl }) {
  return (
    <Container>
      <Image style={{ backgroundImage: `url(${imageUrl})` }} />
      <Title>{title}</Title>
    </Container>
  );
}
